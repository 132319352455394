import React from 'react';
import {Card, message, Popconfirm, Skeleton, Table} from "antd";
import {useSelector} from "react-redux";
import axios from "axios";
import {DeleteOutlined} from "@ant-design/icons";
import loadable from "@loadable/component";
import SearchBar from "../../components/members/members-table/searchbar.component";
import {Main} from "../../index.styles";

const Img = loadable(() => import('react-image'));

const DeleteMember = () => {

  const columns = [
    {
      dataIndex: 'id',
      render: (id) =>
        <Popconfirm
          title="Delete Member"
          description="Are you sure to delete this member"
          onConfirm={() => {
            confirmDelete(id)
          }}
          okText="Yes"
          cancelText="No"
        >
          {/*{Object.values(admin).includes(userState.email) &&*/}
          <div style={{cursor: 'pointer', color: 'red'}}><DeleteOutlined/></div>
          {/*}*/}
        </Popconfirm>,

      width: '5%'
    },
    {
      dataIndex: 'memberImage',
      render: (memberImage) =>
        <Card
          style={{height: 100}}
          cover={
            <Img
              src={memberImage === '' ? "/avatar.png" : memberImage}
              loader={
                <Skeleton
                  active avatar={{
                  shape: 'square'
                }}/>
              }
            />
          }
        >
        </Card>,
      width: '10%'
    },
    {
      title: 'FName',
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: 'LName',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    }
  ];

  const {filteredData} = useSelector(state => state.search);
  const table = useSelector(state => state.table);

  const confirmDelete = (id) => {

    axios.post(`/member/delete`, {
      data: {
        id: id
      }
    }).then(r =>
      message.success('Member successfully Deleted', 2)
        .then(() =>
          message.info("Page will be refreshed", 1))
        .then(() => {
          window.location.reload();
        }).then(() => {
      })
    );

  };

  return (
    <>
      <Main>
        <SearchBar/>

        <Table
          size={'middle'}
          scroll={{x: true}}
          loading={table.loading}
          columns={columns}
          dataSource={filteredData.length === 0 ? table.items : filteredData}
        />
      </Main>
    </>
  );
};

export default DeleteMember;
