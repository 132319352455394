import {Button, Input, message, Popconfirm} from "antd";
import React, {useState} from "react";
import axios from "axios";

const AddMember = () => {

  const stylesInput = {
    marginBottom: 10, padding: 10, borderRadius: 5,
    width: '-webkit-fill-available'
  }

  const [state, setState] = useState({});


  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };


  const handleUpload = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", img);
    data.append("upload_preset", 'qgsvs5yv');
    data.append("cloud_name", 'djagznbnb');
    // data.append("folder","your-folder-name");


    /*added initially from stripe*/
    // membersData.forEach(m => {
    //   axios.post('/member',
    //     {
    //       data: {
    //         memberImage: '',
    //         last_name: m.last_name,
    //         first_name: m.first_name,
    //         role: m.role,
    //         email: m.email,
    //         phone: m.phone,
    //         address: m.address
    //       }
    //     })
    //     .then((e) => {
    //       // console.log(e.data)
    //     }).then(() => {
    //
    //     message.success('Member successfully created', 2)
    //       .then(() =>
    //         message.info("Page will be refreshed", 2))
    //       .then(() => {
    //         window.location.reload();
    //       }).then(() => {
    //     });
    //
    //   })
    //     .catch(e => {
    //       console.log(e)
    //     });
    // })

    axios.post(`https://api.cloudinary.com/v1_1/djagznbnb/image/upload`, data)
      .then(r => {

        axios.post('/member',
          {
            data: {
              memberImage: r.data.secure_url,
              last_name: state.last_name,
              first_name: state.first_name,
              role: state.role,
              email: state.email,
              phone: state.phone,
              address: state.address
            }
          })
          .then((e) => {
            // console.log(e.data)
          }).then(() => {

          message.success('Member successfully created', 2)
            .then(() =>
              message.info("Page will be refreshed", 2))
            .then(() => {
              window.location.reload();
            }).then(() => {
          });

        })
          .catch(e => {
            console.log(e)
          });

      }).catch(err => {
      console.log(err)
    });
  }

  const [img, setImg] = useState("");

  const updateImage = (e) => {
    setImg(e.target.files[0]);
  }


  return (
    <>
      <form style={{marginTop: 20, maxWidth: 500, margin: 'auto'}}>
        <div>
          <h1>New member</h1>

          <h5 style={{color: "#b4b4b4"}}>Member Image (drag image or click to choose) </h5>
          <input
            style={stylesInput}
            type="file"
            onChange={updateImage}
            className="form-control shadow-sm"
            id="image"
            name="image"
            accept="image/*"
          />
          <Input
            style={stylesInput}
            name={'last_name'}
            placeholder={'Last Name'}
            onChange={updateField}
          />
          <Input
            style={stylesInput}
            name={'first_name'}
            onChange={updateField}
            placeholder={'First Name'}
          />
          <Input
            style={stylesInput}
            name={'role'}
            onChange={updateField}
            placeholder={'role'}
          />
          <Input
            style={stylesInput}
            name={'email'}
            onChange={updateField}
            placeholder={'email'}
          />

          <Input
            style={stylesInput}
            name={'address'}
            onChange={updateField}
            placeholder={'Address'}
          />


          <Input
            style={stylesInput}
            name={'phone'}
            onChange={updateField}
            placeholder={'phone'}
          />


          <Popconfirm placement="top" title={"Free of errors?"}
                      onConfirm={handleUpload}
                      okText="Yes" cancelText="No">

            <Button
              variation="primary"
              type="submit"
              onClick={event => event.preventDefault()}
            >
              Create
            </Button>

          </Popconfirm>
        </div>
      </form>
    </>)
}

export default AddMember;
