import React from 'react';
import {S3Image} from "aws-amplify-react";

const Image = ({imgKey, width = '450px', maxHeight = '500px', height, paddingRight, opacity}) => {
  return (
    <img
      src={imgKey}
      style={{
        width: width,
        maxHeight: maxHeight,
        height: height,
        objectFit: 'cover',
        paddingBottom: 2,
        opacity: opacity,
        paddingRight: paddingRight,
      }}
    />
  );

};

export default Image;