import {imported} from "../../importedMembersData";
import {useDispatch} from "react-redux";
import {filterData} from "../../redux/searchBox/searchBox.actions";
import {useMemo, useState} from "react";
import {
  fetchDataTableBegin,
  fetchDataTableFailure,
  fetchDataTableSuccess
} from "../../redux/tableData/tableData.actions";
// import {uniqueMemberPayments} from "../../helpers";
import axios from 'axios';
import useIsMounted from "../../useIsMounted";


const useMembers = () => {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const [membersData, setMembersData] = useState([]);


  useMemo(() => {
      const fetchTableData = async () => {

        const arr = [];
        dispatch(fetchDataTableBegin());
        axios.get('/member')
          .then(r => {

            Object.entries(r.data).forEach(([key, d]) => {
              arr.push({
                id: d._id,
                key: key,
                last_name: d.last_name,
                first_name: d.first_name,
                role: d.role,
                email: d.email,
                memberImage: d.memberImage,
                address: d.address
              });
            });
          }).then(() => {
          arr.sort((a, b) =>
            a.last_name < b.last_name ? -1 : 1)
          if (isMounted.current) {
            setMembersData(arr);
            dispatch(fetchDataTableSuccess(arr));
          }


        }).catch(e => {
          dispatch(fetchDataTableFailure(e));
        });


        //   dispatch(fetchDataTableBegin());
        //   try {
        //     // const members = await axios.get('/member');
        //
        //     const subscriptionsPaymentsList = await axios.get('/customer/list');
        //     const currentMembersData = subscriptionsPaymentsList.data.data.map(d => d.metadata);
        //     const allData = [...imported, ...currentMembersData];
        //     if (isMounted.current) {
        //       const uniqueMembers = uniqueMemberPayments(allData);
        //       setMembersData(uniqueMembers);
        //       dispatch(fetchDataTableSuccess(uniqueMembers));
        //     }
        //
        //
        //   } catch (e) {
        //     dispatch(fetchDataTableFailure(e));
        //   }
        // }
        //;
      }
      fetchTableData();
    }, [dispatch, isMounted]
  );


  const handleChange = (e) => {
    e.persist();
    const inputValue = e.target.value;
    const filtered = membersData.filter(data =>
      Object.keys(data)
        .some(k => data[k].toString().toLowerCase()
          .includes((inputValue).toLowerCase())));
    dispatch(filterData(filtered))
  };

  return {
    handleChange,
    membersData,
    membersEmails: membersData.map(user => user.email),
    membersLastName: membersData.map(user => user.last_name),
    membersFirstName: membersData.map(user => user.first_name)
  }
};

export default useMembers;