import React, {Suspense} from "react";
import 'antd/dist/antd.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import Nav from "./components/nav-components/nav-top.component";
import Footer from "./components/footer/footer.component";
import {useLoginStatus} from "./useLoginStatus";
import useEvents from "./auth-amplify/useEvents";
import useMembers from "./components/members/useMembers";
import * as load from './load-page';

import Amplify from 'aws-amplify';
import aws_config from './aws-exports';
import PostShow from "./components/blog/post/post-show/post-show.component";
import RegisteredForMeeting from "./pages/virtualMeetings/RegisteredForMeeting";
import AllEmails from "./pages/Emails/AllEmails";
import AddMember from "./pages/members/AddMember";
import DeleteMember from "./pages/members/DeleteMember";

Amplify.configure(aws_config);

const App = () => {
  useLoginStatus();
  useEvents();
  const {membersEmails} = useMembers();

  return (
    <Router>
      <Nav/>
      <Switch>
        <Suspense fallback={<></>}>
          <Route exact path='/'>
            <load.Home/>
          </Route>
          <Route path='/about'>
            <load.AboutPage/>
          </Route>
          <Route path='/membership'>
            <load.MembershipPage/>
          </Route>
          <Route path='/all-members'>
            <load.AllMembersPage/>
          </Route>
          <Route path='/contact-us'>
            <load.ContactPage/>
          </Route>
          <Route path='/hippocrates'>
            <load.HippocratesPage/>
          </Route>
          <Route path='/donate'>
            <load.DonatePage/>
          </Route>
          <Route exact path='/posts'>
            <load.PostsPage membersEmails={membersEmails}/>
          </Route>
          <Route path={'/posts/:postId'}><PostShow/></Route>
          {/* //-------------------------------------------------------------------------------*/}
          {/*  need to change the model schema name to 'registrations or registrations2 ...' in models folder iff want to receive the data for the specific registration*/}

          {/*<Route path={'/registrations'}><RegisteredForMeeting/></Route>*/}
          <Route path={'/registrations2'}><RegisteredForMeeting/></Route>
          <Route path={'/emails'}><AllEmails/></Route>
          <Route path={'/add-member'}><AddMember/></Route>
          <Route path={'/delete-member'}><DeleteMember/></Route>

          {/*  //---------------------------------------------------------------------------------*/}
        </Suspense>
      </Switch>
      <Footer/>
    </Router>
  );
};

export default App;
