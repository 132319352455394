export const FETCH_POSTS_START = "FETCH_POSTS_START";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE";

export const NEW_POST_START = "NEW_POSTS_START";
export const NEW_POST_SUCCESS = "NEW_POSTS_SUCCESS";
export const NEW_POST_FAILURE = "NEW_POST_FAILURE";

export const EDIT_POST_START = "EDIT_POST_START";
export const EDIT_POST_SUCCESS = "EDIT_POST_SUCCESS";
export const EDIT_POST_CANCEL = "EDIT_POST_CANCEL";
export const EDIT_POST_FAILURE = "EDIT_POST_FAILURE";

export const DELETE_POST_START = "DELETE_POST_START";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const SHOW_POST = "SHOW_POST";