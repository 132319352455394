import React, {useEffect, useState} from "react";
import {MainSection} from "../home/homePage.styles";
import axios from "axios";
import {notification, Table} from "antd";

const RegisteredForMeeting = () => {
  const [attendees, setAttendees] = useState('');
  const [attendeesFromFile, setAttendeesFromFile] = useState('');

  useEffect(() => {
    axios
      .get("/virtualMeeting")
      .then(response => {
        setAttendees(response.data);
      });

    axios
      .get("/virtualMeeting/fromFile")
      .then(response => {
        console.log(response)
        setAttendeesFromFile(response.data);
      }).catch(e => {
      console.log(e)
    });

  }, [setAttendees, setAttendeesFromFile]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Affiliation',
      dataIndex: 'affiliation',
      key: 'affiliation',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'areas',
      dataIndex: 'areasOfInterest',
      key: 'areasOfInterest',
    },
  ];


  return (
    <>

      <h2>Database Values</h2>
      <Table dataSource={Object.values(attendees)} columns={columns} rowKey={row => row._id}/>;
      <MainSection>
        <h2>All Emails</h2>
        {Object.values(attendees).map((item, index) =>
          <div key={item._id}>
            {item.email},
          </div>)}


      {/*  <br/>
        <h3>from file in case database fails<br/></h3>
        {attendeesFromFile}*/}
      </MainSection>
    </>)
}

export default RegisteredForMeeting;