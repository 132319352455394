import React from 'react'
import useMembers from "../../components/members/useMembers";
import {Card} from "antd";

const AllEmails = () => {
  const {membersData, membersEmails} = useMembers();
  const listItems = membersData.map(({email, first_name, last_name}, index) =>
    <div key={index.toString()}>
      {/*<b>{first_name} {last_name}: </b> <span style={{color:'purple'}}>{email}</span>*/}
      <Card
        title={<b>{first_name} {last_name}</b>}
        extra={<span>{email}</span>}
      />
    </div>
  );

  return <>
    <div style={{maxWidth: 500, margin: "auto"}}>
      <h3 style={{textDecoration: "underline"}}>Emails Count: {membersEmails.length} </h3>
      <>{listItems}</>
    </div>
  </>
}

export default AllEmails