import React from 'react';
import Image from "./image.component";

/**
 *
 * @param post
 * @param height
 * @param width
 * @param maxHeight
 * @param opacity
 * @param isShowPage boolean, true if it is the page that expands images (posts-show)
 *         else false (all-posts-list should not expand images)
 * @returns {*[]}
 */

const MoreImagesList = ({post, height, width, maxHeight, opacity, isShowPage}) => {
  return (
    post.images.map((src, index) => (
      <div key={index} onClick={() => isShowPage &&
        window.open(src)}>
        {index > 0 &&
        <Image
          imgKey={src}
          height={height}
          width={width}
          maxHeight={maxHeight}
          paddingRight={2}
          opacity={opacity}
        />
        }
      </div>
    ))
  );
};

export default MoreImagesList;