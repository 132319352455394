// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:e4b9c8fa-f90a-402b-af53-8d38bcbcefd5",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ybResIuxb",
    "aws_user_pools_web_client_id": "5sol88jqch8is9v285h63hr172",
    "oauth": {}
};

export default awsmobile;
